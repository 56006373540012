// Highlight active navigation link based on scroll position
document.addEventListener("DOMContentLoaded", function () {
  // Get all section elements
  const sections = document.querySelectorAll("section");

  // Get all navigation links
  const navLinks = document.querySelectorAll(".navlink");

  // Function to update the active link based on scroll position
  function updateActiveLink() {
    let currentSection = "";

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 50;
      const sectionBottom = sectionTop + section.offsetHeight;

      if (window.scrollY >= sectionTop && window.scrollY < sectionBottom) {
        currentSection = section.id;
      }
    });

    // Remove the 'active' class from all navigation links
    navLinks.forEach((link) => {
      link.classList.remove("active");
    });

    // Add the 'active' class to the link corresponding to the current section
    const activeLink = document.querySelector(
      `.navlink[href="#${currentSection}"]`
    );
    if (activeLink) {
      activeLink.classList.add("active");
    }
  }

  // Attach the updateActiveLink function to the scroll event
  window.addEventListener("scroll", updateActiveLink);
});

// Close mobile menu when menu link is clicked
const menuLinks = document.querySelectorAll(".mobile-nav__link");

menuLinks.forEach((link) => {
  link.addEventListener("click", () => {
    // Unckeck checkbox
    document.getElementById("toggle-nav").checked = false;
  });
});

// Inject current year into footer
const currentYear = document.getElementById("current-year");
currentYear.textContent = new Date().getFullYear();

// Portfolio section filter buttons functionality
document.addEventListener('DOMContentLoaded', () => {
  const filterBtns = document.querySelectorAll('.filter-btn');
  const portfolioItems = document.querySelectorAll('.portfolio-item');

  filterBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      const filter = btn.getAttribute('data-filter');
      
      filterBtns.forEach(btn => btn.classList.remove('active'));
      btn.classList.add('active');

      portfolioItems.forEach(item => {
        if (filter === 'all' || item.getAttribute('data-category') === filter) {
          item.style.display = 'grid';
        } else {
          item.style.display = 'none';
        }
      });
    });
  });
});

// Function to initialize portfolio filters
function initializePortfolioFilters() {
  const filterBtns = document.querySelectorAll('.filter-btn');
  const portfolioItems = document.querySelectorAll('.portfolio-item');

  if (filterBtns.length === 0 || portfolioItems.length === 0) {
    console.warn('Portfolio filter elements not found. Make sure the HTML structure is correct.');
    return;
  }

  filterBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      const filter = btn.getAttribute('data-filter');
      
      filterBtns.forEach(btn => btn.classList.remove('active'));
      btn.classList.add('active');

      portfolioItems.forEach(item => {
        if (filter === 'all' || item.getAttribute('data-category') === filter) {
          item.style.display = 'grid';
        } else {
          item.style.display = 'none';
        }
      });
    });
  });
}

// Call the initialization function when the DOM is loaded
document.addEventListener('DOMContentLoaded', initializePortfolioFilters);
